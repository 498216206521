export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'Застройки',
        id: 'developments',
        elements: [
            {
                text: 'Нахиль',
                id: 'nakheel'
            },
            {
                text: 'Мейдан',
                id: 'meydan'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Тип проекта',
        id: 'project_type',
        elements: [
            {
                text: 'Виллы',
                id: 'villas'
            },
            {
                text: 'Квартиры',
                id: 'apartments'
            },
            {
                text: 'Участки',
                id: 'plots'
            },
            {
                text: 'Квартиры и Виллы',
                id: 'apartments_villas'
            },
            {
                text: 'Участки и Виллы и Квартиры',
                id: 'plots_villas_apartments'
            },
            {
                text: 'Показать все',
                id: '_all_project_type'
            },
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Инфраструктура',
        id: 'infrastructure',
        elements: [
            {
                text: 'Достопримечательности',
                id: 'landmarks'
            },
            {
                text: 'Транспорт (Метро)',
                id: 'transport'
            },
            {
                text: 'Образование',
                id: 'education'
            },
            {
                text: 'Здоровье',
                id: 'health'
            },
            {
                text: 'Розничная торговля или Магазины',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}